<template>
  <div class="content">
    <!-- 第一个-->
    <nav-box></nav-box>
    <div class="cnt-one w" v-if="levels === '1'">
      <p class="title">不锈钢焊条询价公告</p>
      <p>
        现拟以谈判采购方式采购不锈钢螺栓，欢迎贵方就以下采购内容进行报价。现将有关事项说明如下:
      </p>
      <p class="title-small">一、货物需求</p>
      <div class="cnt">
        <p>1、报价有效期限：30天</p>
        <p>2、付款方式：货到验收合格并开具含13%的增值税专用发票挂账后付款，留10%质</p>
        <p>3、保质期:1年</p>
        <p>4、交货时间：合同生效后3天内</p>
        <p>5、售后服务要求：保修期内，在接到采购单位报修电话后，供应商应在2小时内上门服务</p>
      </div>
      <p class="title-small">二、询价响应人的资格要求</p>
      <div class="cnt">
        <p>1、在中国境内注册、具有独立企业法人资格，能在国内合法生产或销售和提供招标货物及相应服务的制造商或代理商。注册资金需在500万人民币以上。</p>
        <p>2、参与报价单位应具有一定规模、良好的财务和资信状况，具有在国内煤矿业销售同类货物的业绩和经验，以及设计、制造和维护等方面的专业技术人员。</p>
        <p>3、代理商作为报价机构应具有制造商为本次报价而出具的授权书和技术服务承诺书。代理商销售产品在设计、制造和维护等方面在合作的同时提供相应的服务</p>
        <p>4、报价机构及单位的报价货物须具备相应的资格证书（请选择但不限于：“产品出厂检验合格证”、“产品计量合格证”和“防爆合格证”）。</p>
        <p>5、本项目不接受联合体投标。</p>
      </div>
    </div>
    <!-- 第二个-->
    <div class="cnt-one w" v-if="levels === '2'">
      <p class="title">内六角螺栓询价公告</p>
      <p>
        现拟以谈判采购方式采购内六角螺栓，欢迎贵方就以下采购内容进行报价。现将有关事项说明如下:
      </p>
      <p class="title-small">一、货物需求</p>
      <div class="cnt">
        <p>1、报价有效期限：30天</p>
        <p>2、付款方式：货到验收合格并开具含13%的增值税专用发票挂账后付款，留10%质</p>
        <p>3、保质期:1年</p>
        <p>4、交货时间：合同生效后3天内</p>
        <p>5、售后服务要求：保修期内，在接到采购单位报修电话后，供应商应在2小时内上门服务</p>
      </div>
      <p class="title-small">二、询价响应人的资格要求</p>
      <div class="cnt">
        <p>1、在中国境内注册、具有独立企业法人资格，能在国内合法生产或销售和提供招标货物及相应服务的制造商或代理商。注册资金需在500万人民币以上。</p>
        <p>2、参与报价单位应具有一定规模、良好的财务和资信状况，具有在国内煤矿业销售同类货物的业绩和经验，以及设计、制造和维护等方面的专业技术人员。</p>
        <p>3、代理商作为报价机构应具有制造商为本次报价而出具的授权书和技术服务承诺书。代理商销售产品在设计、制造和维护等方面在合作的同时提供相应的服务</p>
        <p>4、报价机构及单位的报价货物须具备相应的资格证书（请选择但不限于：“产品出厂检验合格证”、“产品计量合格证”和“防爆合格证”）。</p>
        <p>5、本项目不接受联合体投标。</p>
      </div>
    </div>
    <!-- 第三个-->
    <div class="cnt-one w" v-if="levels === '3'">
      <p class="title">螺栓询价公告</p>
      <p>
        现拟以谈判采购方式采购螺栓，欢迎贵方就以下采购内容进行报价。现将有关事项说明如下:
      </p>
      <p class="title-small">一、货物需求</p>
      <div class="cnt">
        <p>1、报价有效期限：30天</p>
        <p>2、付款方式：货到验收合格并开具含13%的增值税专用发票挂账后付款，留10%质</p>
        <p>3、保质期:1年</p>
        <p>4、交货时间：合同生效后3天内</p>
        <p>5、售后服务要求：保修期内，在接到采购单位报修电话后，供应商应在2小时内上门服务</p>
      </div>
      <p class="title-small">二、询价响应人的资格要求</p>
      <div class="cnt">
        <p>1、在中国境内注册、具有独立企业法人资格，能在国内合法生产或销售和提供招标货物及相应服务的制造商或代理商。注册资金需在500万人民币以上。</p>
        <p>2、参与报价单位应具有一定规模、良好的财务和资信状况，具有在国内煤矿业销售同类货物的业绩和经验，以及设计、制造和维护等方面的专业技术人员。</p>
        <p>3、代理商作为报价机构应具有制造商为本次报价而出具的授权书和技术服务承诺书。代理商销售产品在设计、制造和维护等方面在合作的同时提供相应的服务</p>
        <p>4、报价机构及单位的报价货物须具备相应的资格证书（请选择但不限于：“产品出厂检验合格证”、“产品计量合格证”和“防爆合格证”）。</p>
        <p>5、本项目不接受联合体投标。</p>
      </div>
    </div>
    <div class="w out" @click="getOut">返回</div>
  </div>
</template>

<script>
import NavBox from '@/components/common/navBox'
export default {
  components: { NavBox },
  data () {
    return {
      levels: ''
    }
  },
  created () {
    this.getLevel()
  },
  methods: {
    getLevel () {
      this.levels = sessionStorage.getItem('level')
    },

    getOut () {
      this.$router.push('/enquiry')
    }
  },
  destroyed () {
    sessionStorage.removeItem('level')
  }
}
</script>

<style scoped lang="less">
.w {
  width: 1200px;
  margin: 0 auto;
}
.out {
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.content{
  width: 100%;
  min-height: auto;
}
.title{
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}
.title-small{
  font-size: 16px;
  font-weight: bolder;
}
.cnt{
  font-size: 12px;
}
</style>
